import { ABSOLUTE_ROUTES } from '@common/enums'
import { Stars } from '@components/common/icons/custom-icons'
import { FastlyImage } from '@components/common/image'
import { Trans } from '@lingui/macro'

const { REVIEWS } = ABSOLUTE_ROUTES
const COMMON_IMAGE_PATH = '/images/common/partners/'

type RatingsProps = {
  isShowLink?: boolean
  isDarkText?: boolean
}

const Ratings = ({ isShowLink = true, isDarkText = false }: RatingsProps) => {
  return (
    <div className="flex space-x-4 items-center text-white">
      <div>
        <FastlyImage
          src={`${COMMON_IMAGE_PATH}google-medium.png`}
          width={60}
          height={20}
        />
        <Stars className="mb-2" />
        <p className={`${isDarkText && 'text-grey'}`}>4.8 / 5 stars</p>
      </div>
      <span
        className={`h-10 ${isDarkText ? 'bg-grey-300' : 'bg-grey-700'}`}
        style={{ width: '1px' }}
      />
      <div>
        <FastlyImage
          src={`${COMMON_IMAGE_PATH}trust-pilot-half${
            isDarkText ? '-black' : ''
          }.png`}
          width={100}
          height={45}
        />
        <p className={`${isDarkText && 'text-grey'}`}>4.7 / 5 stars</p>
      </div>
      {isShowLink && (
        <>
          <span className="h-10 bg-grey-700" style={{ width: '1px' }} />
          <div>
            <Trans>
              <a
                href={REVIEWS}
                target="_blank"
                rel="noopener noreferrer"
                className={`font-600 hover:underline ${
                  isDarkText && 'text-grey'
                }`}
              >
                Read reviews
              </a>
            </Trans>
          </div>
        </>
      )}
    </div>
  )
}
export default Ratings
