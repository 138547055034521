import BookFreeClassButton from '@components/common/book-free-class-btn'
import { FastlyImage } from '@components/common/image'
import Modal from '@components/common/modal'
import { Trans } from '@lingui/macro'
import { useState } from 'react'

const IMAGE_PATH = '/images/home/dashboard-demo-1.png'

type Props = {
  shouldScrollToTop?: boolean
  urlQuery?: string
}
const LearningDashboard = ({ shouldScrollToTop, urlQuery }: Props) => {
  const [ismodalOpen, setModalOpen] = useState(false)

  return (
    <>
      <Modal
        isOpen={ismodalOpen}
        setIsOpen={setModalOpen}
        isCrossIconShown={true}
        shouldCloseOnEsc
        width="mt-12 sm:mt-5"
        className="bg-black pl-0 pr-0"
      >
        <div>
          <iframe
            src="https://iframe.mediadelivery.net/embed/239253/c6d3436d-4a2d-4905-b70a-8c55533c5b6a?autoplay=true&loop=true&muted=false&preload=false&responsive=true"
            className="-my-5 sm:-my-10 rounded-lg w-full h-80 sm:h-screen"
            allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
      <div className="container mx-auto flex lg:flex-row flex-col items-center lg:space-x-16 leading-tight py-16 text-grey px-5">
        <div
          className="mb-5 lg:mb-0 cursor-pointer"
          onClick={() => setModalOpen(true)}
        >
          <FastlyImage
            src={IMAGE_PATH}
            height={308}
            width={544}
            className="hover:shadow-md"
          />
        </div>
        <div>
          <h2 className="text-2xl lg:text-4xl font-600">
            <Trans id="homepage.learningDashboardFold.heading">
              Intuitive learning dashboard for kids and parents
            </Trans>
          </h2>

          <ul className="mx-0 pl-5 my-10 flex flex-col space-y-2 text-lg">
            <li>
              <Trans>Class management & progress tracking</Trans>
            </li>
            <li>
              <Trans>Easy rescheduling of classes</Trans>
            </li>
            <li>
              <Trans>Session notes</Trans>
            </li>
            <li>
              <Trans>Certificates of completion</Trans>
            </li>
            <li>
              <Trans>Course and instructor information</Trans>
            </li>
            <li>
              <Trans>
                Access to global coding competitions, masterclasses, quizzes,
                resources and much more.
              </Trans>
            </li>
          </ul>

          <BookFreeClassButton
            size="xl"
            shouldScrollToTop={shouldScrollToTop}
            query={urlQuery}
          />
        </div>
      </div>
    </>
  )
}

export default LearningDashboard
