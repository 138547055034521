import { staticFile } from '@common/utils'
import 'lazysizes'

type BackgroundImageComponentProps = {
  className?: string
  image: string
  top?: number | string
  bottom?: number | string
  left?: number | string
  right?: number | string
  marginTop?: number
  marginBottom?: number
  marginLeft?: number
  marginRight?: number
  opacity?: number
  height?: number
  width?: number
  zIndex?: number
  alt?: string
  transform?: string
}

const BackgroundImageComponent = ({
  className = 'hidden lg:flex',
  image,
  top,
  bottom,
  left,
  right,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  opacity,
  height,
  width,
  zIndex = -1,
  alt = 'shape',
  transform,
}: BackgroundImageComponentProps): JSX.Element => (
  <img
    data-src={staticFile(image)}
    style={{
      position: 'absolute',
      top: `${top}%`,
      bottom: `${bottom}%`,
      left: `${left}%`,
      right: `${right}%`,
      marginTop: `${marginTop}%`,
      marginBottom: `${marginBottom}%`,
      marginLeft: `${marginLeft}%`,
      marginRight: `${marginRight}%`,
      height: `${height}rem`,
      width: `${width}rem`,
      opacity: `${opacity}%`,
      zIndex,
      transform,
    }}
    className={`${className} lazyload`}
    alt={alt}
  />
)

export default BackgroundImageComponent
