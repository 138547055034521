import BookFreeClassBtn from '@components/common/book-free-class-btn'
import MsgTrans from '@components/common/i18n/message-trans'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { defineMessage, Trans } from '@lingui/macro'
import { CheckCircle } from 'react-feather'

export type PriceType = {
  maxClassesPerMonth: number
  link: string
  pricing: Record<string, PricingType>
  isBestSeller: boolean
  features: LinguiJSMessageDescriptor[]
}
export type PricingType = {
  discountedPriceperMonth: number
}

// Price last updated on MAR 30 2023

export const PRICES: PriceType[] = [
  {
    maxClassesPerMonth: 8,
    link: 'https://buy.stripe.com/dR601JeCx1UDf7yg2i',
    isBestSeller: true,
    pricing: {
      IN: {
        discountedPriceperMonth: 8999,
      },
      PK: {
        discountedPriceperMonth: 29999,
      },
      BD: {
        discountedPriceperMonth: 11499,
      },
      NP: {
        discountedPriceperMonth: 14400,
      },
      LK: {
        discountedPriceperMonth: 34999,
      },
      US: {
        discountedPriceperMonth: 239,
      },
      CA: {
        discountedPriceperMonth: 339,
      },
      NG: {
        discountedPriceperMonth: 79999,
      },
      KE: {
        discountedPriceperMonth: 22999,
      },
      UG: {
        discountedPriceperMonth: 660096,
      },
      EU: {
        discountedPriceperMonth: 199,
      },
      UK: {
        discountedPriceperMonth: 199,
      },
    },
    features: [
      defineMessage({
        id: 'homepage.pricingFold.8classesPerMonth.featureText1',
        message: '2 classes per week',
      }),
      defineMessage({
        id: 'homepage.pricingFold.8classesPerMonth.featureText2',
        message: '8+ fun and engaging activities',
      }),
      defineMessage({
        id: 'homepage.pricingFold.8classesPerMonth.featureText3',
        message: '8 Coding quizzes',
      }),
      defineMessage({
        id: 'homepage.pricingFold.8classesPerMonth.featureText4',
        message: 'Access to complete coding module',
      }),
      defineMessage({
        id: 'homepage.pricingFold.8classesPerMonth.featureText5',
        message:
          'Expert-designed, project-based curriculum paired with hands-on activities and projects',
      }),
      defineMessage({
        id: 'homepage.pricingFold.8classesPerMonth.featureText6',
        message: 'Progress Tracking - Parent Teacher Meet',
      }),
      defineMessage({
        id: 'homepage.pricingFold.8classesPerMonth.featureText8',
        message: 'Unlimited doubt sessions, available 24*7',
      }),
    ],
  },
  {
    maxClassesPerMonth: 12,
    link: 'https://buy.stripe.com/8wM29R2TPeHp9NeeYf',
    isBestSeller: false,
    pricing: {
      IN: {
        discountedPriceperMonth: 12999,
      },
      PK: {
        discountedPriceperMonth: 43999,
      },
      BD: {
        discountedPriceperMonth: 16999,
      },
      NP: {
        discountedPriceperMonth: 20999,
      },
      LK: {
        discountedPriceperMonth: 51999,
      },
      US: {
        discountedPriceperMonth: 359,
      },
      CA: {
        discountedPriceperMonth: 499,
      },
      NG: {
        discountedPriceperMonth: 119999,
      },
      KE: {
        discountedPriceperMonth: 33999,
      },
      UG: {
        discountedPriceperMonth: 989999,
      },
      EU: {
        discountedPriceperMonth: 279,
      },
      UK: {
        discountedPriceperMonth: 269,
      },
    },
    features: [
      defineMessage({
        id: 'homepage.pricingFold.12classesPerMonth.featureText1',
        message: '3 classes per week',
      }),
      defineMessage({
        id: 'homepage.pricingFold.12classesPerMonth.featureText2',
        message: '12+ fun and engaging activities',
      }),
      defineMessage({
        id: 'homepage.pricingFold.12classesPerMonth.featureText3',
        message: '12 Coding quizzes',
      }),
      defineMessage({
        id: 'homepage.pricingFold.12classesPerMonth.featureText4',
        message: 'Access to complete coding modules',
      }),
      defineMessage({
        id: 'homepage.pricingFold.12classesPerMonth.featureText5',
        message:
          'Expert-designed, project-based curriculum paired with hands-on activities and projects',
      }),
      defineMessage({
        id: 'homepage.pricingFold.12classesPerMonth.featureText6',
        message: 'Progress Tracking - Parent Teacher Meet',
      }),
      defineMessage({
        id: 'homepage.pricingFold.12classesPerMonth.featureText8',
        message: 'Unlimited doubt sessions, available 24*7',
      }),
    ],
  },
]

const Pricings = () => {
  return (
    <div className="container mx-auto flex flex-col space-y-10 leading-tight py-16 text-grey px-5">
      <div className="text-xl max-w-2xl mx-auto text-center">
        <h2 className="text-2xl lg:text-4xl font-600 mb-3">
          <Trans id="homepage.pricingFold.heading">
            <span className="yellow-underline">Flexible pricing</span> with{' '}
            <span className="yellow-underline">personalized classes</span>
          </Trans>
        </h2>
      </div>

      <div className="flex flex-wrap justify-center">
        {PRICES.map((price, i) => (
          <div
            key={i}
            className="relative flex flex-col shadow hover:shadow-lg transition-all duration-200 rounded-md w-full md:w-96 m-3"
            style={{ height: '420px' }}
          >
            <div className="relative text-center text-grey rounded">
              {price.isBestSeller && <BestSellerTag />}
              <h3 className="text-xl font-600 hero-gradient-purple-red z-10 text-white absolute left-0 top-0 w-full py-4 rounded-t-md">
                {price.maxClassesPerMonth}{' '}
                <Trans id="homepage.pricingFold.classesPerMonthText">
                  classes / month
                </Trans>
              </h3>
            </div>

            <ul className="flex flex-col space-y-3 list-none pt-20 px-5">
              {price.features.map((feature, i) => (
                <li key={i} className="flex items-center space-x-2 text-lg">
                  <div>
                    <CheckCircle size={18} color="#8A35BC" />
                  </div>
                  <div>
                    <MsgTrans msg={feature} />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="mx-auto px-5 md:px-0 w-full lg:w-auto lg:px-0">
        <BookFreeClassBtn size="xl" />
      </div>
    </div>
  )
}

const BestSellerTag = () => (
  <span className="bg-orange text-white font-600 px-6 py-1 rounded shadow absolute z-20 top-0 left-0 right-0 mx-auto w-40 -mt-3">
    <Trans id="homepage.pricingFold.bestSellerTag">Bestseller</Trans>
  </span>
)

export default Pricings
