import { Trans } from '@lingui/macro'
import { Monitor, Zap } from 'react-feather'

const PriceSlotsOverview = () => {
  return (
    <div className="flex justify-center lg:justify-start flex-wrap md:space-x-6">
      <div className="flex items-center">
        <Monitor className="text-orange mr-1" size={18} />
        <p>
          <Trans>
            Free class <span className="line-through">worth $20</span>
          </Trans>
        </p>
      </div>
      <div className="flex items-center">
        <Zap className="text-orange mr-1" size={18} />
        <p>
          <Trans>1000+ classes booked in last 24 hours</Trans>
        </p>
      </div>
    </div>
  )
}
export default PriceSlotsOverview
