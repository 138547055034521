import { Trans } from '@lingui/macro'
import classNames from 'classnames'

type Props = {
  isInGlobalHomepage?: boolean
}
const StudentCountriesOverview = ({ isInGlobalHomepage = true }: Props) => {
  return (
    <div
      className={classNames('flex justify-center space-x-10 lg:justify-start', {
        'text-white': isInGlobalHomepage,
        'text-grey': !isInGlobalHomepage,
      })}
    >
      <div className="flex flex-col">
        <span className="inline-block text-xl font-700">
          <Trans>500,000+</Trans>
        </span>
        <p className="relative inline-block">
          <Trans>Students</Trans>
          <br />
          <span
            className={classNames(
              'absolute bottom-0 inline-block w-6 border-b',
              {
                'text-grey-600': isInGlobalHomepage,
                'text-orange': !isInGlobalHomepage,
              }
            )}
          />
        </p>
      </div>
      <div className="flex flex-col">
        <span className="inline-block text-xl font-700">
          <Trans>70+</Trans>
        </span>
        <p className="relative inline-block">
          <Trans>Countries</Trans>
          <br />
          <span
            className={classNames(
              'absolute bottom-0 inline-block w-6 border-b',
              {
                'text-grey-600': isInGlobalHomepage,
                'text-orange': !isInGlobalHomepage,
              }
            )}
          />
        </p>
      </div>
    </div>
  )
}
export default StudentCountriesOverview
