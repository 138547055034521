import Link from 'next/link'
import { FastlyImage } from '@components/common/image'
import { t, Trans } from '@lingui/macro'
import { Quote } from '@components/common/icons/custom-icons'

type FounderCardProps = {
  name: string
  title: string
  bio: string
  quote: string
  path: string
  imageName: string
  alumni: string
  linkedin: string
}

const FounderCard = ({
  name,
  title,
  bio,
  quote,
  path,
  imageName,
  alumni,
}: FounderCardProps) => (
  <div className="relative flex flex-col bg-white rounded-lg max-w-sm px-8 py-12 text-center items-center z-10 shadow-2xl justify-between">
    <p className="text-grey font-600">&ldquo;{quote}&rdquo;</p>
    <FastlyImage
      src={`${path}${imageName}`}
      width={161}
      height={154}
      alt={name}
    />
    <div>
      <p className="text-grey font-700 text-xl">{name}</p>
      <p className="text-grey">{title}</p>
      <p className="text-grey">{alumni}</p>
      <p className="text-grey mt-2" style={{ minHeight: '72px' }}>
        {bio}
      </p>
    </div>
  </div>
)

const Founders = () => (
  <div
    className="flex flex-col relative items-center py-10 lg:py-16 px-5 overflow-x-hidden"
    style={{
      background: `url('/images/home/founders/box-bg.png') repeat-x`,
    }}
  >
    <h2 className="relative text-grey font-600 text-2xl max-w-2xl mx-6 text-center leading-tight lg:text-4xl mb-5">
      <Quote className="text-orange absolute left-0 -mt-2 -ml-5 h-3 lg:h-6" />
      <Trans id="homepage.foundersFold.heading">
        It&apos;s our love and passion for coding and math that drive us to
        teach
      </Trans>
    </h2>

    <div className="mt-5 mb-16 flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-5 relative">
      <FounderCard
        name={t`Vivek Prakash`}
        title={t`Co-founder & CEO, Codingal`}
        bio={t`Former Co-founder and CTO of one of the largest global programming community HackerEarth.`}
        alumni={t`IIT Roorkee`}
        quote={t`Teaching coding and math to kids is a profound responsibility. Our dedicated educators and meticulously crafted curriculum reflect our deep understanding and commitment.`}
        path="/images/home/founders/"
        imageName="vivek-v1.png"
        linkedin="https://www.linkedin.com/in/prakashvivek/"
      />
      <FounderCard
        name={t`Satyam Baranwal`}
        title={t`Co-founder & COO, Codingal`}
        bio={t`Founder of Skillovate — a pioneer company in imparting coding training to children.`}
        alumni={t`IIT Dhanbad`}
        quote={t`Learning to code and solve mathematical problems is not just about going to Mars or the moon. Coding and math are the language that empowers kids at multiple levels.`}
        path="/images/home/founders/"
        imageName="satyam-v1.png"
        linkedin="https://www.linkedin.com/in/satyam-baranwal/"
      />
    </div>
    <Link href="/about-us">
      <a className="text-center align-middle border rounded focus:outline-none font-700 px-6 lg:px-10 py-2 h-11 bg-orange text-white hover:bg-orange-500 active:bg-orange-800 undefined inline-block w-full lg:w-auto">
        <Trans id="homepage.foundersFold.cta">Meet the team</Trans>
      </a>
    </Link>
  </div>
)

export default Founders
